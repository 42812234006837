//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ForgotPassword',
    created() {
        if (this.$store.state.isLoggedIn) {
            this.$router.push({name: 'Home'});
            return;
        }
        if (this.$route.query.uid && this.$route.query.reset_token) {
            this.isLoading = true;
            this.$http({
                url: 'password_resets?uid=' + this.$route.query.uid + '&reset_token=' + this.$route.query.reset_token,
                method: 'GET'
            }).then((response) => {
                this.isLoading = false;
                switch (response.data['status']) {
                    case 'password_reset_exists':
                        this.state = 2;
                        break;
                }
            }).catch((err) => {
                if (err.response.status == 404) {
                    this.$q.notify({
                        message: 'The link your provided is either expired or invalid. Please try again.',
                        color: 'negative'
                    });
                    this.state = 1;
                    this.isLoading = false;
                }
            });
        }
    },
    data() {
        return {
            email: '',
            state: 1,
            isLoading: false,
            password: '',
            confirm_password: ''
        }
    },
    methods: {
        sendPasswordResetRequest() {
            this.isLoading = true;
            this.$http({
                url: 'password_resets',
                method: 'POST',
                data: { email: this.email }
            }).then((response) => {
                this.isLoading = false;
                switch (response.data['status']) {
                    case 'reset_link_sent':
                        this.state = 4;
                        break;
                    case 'reset_link_delay':
                        this.state = 3;
                        break;
                }
            }).catch((err) => {
                if (err.response.status == 404) {
                    this.isLoading = false;
                    this.$q.notify({
                        message: 'The email address that you entered was not found.',
                        color: 'negative'
                    });
                    this.state = 1;
                }
            })
        },
        sendNewPassword() {
            if (!this.$route.query.uid || !this.$route.query.reset_token) {
                this.state = 1;
                return;
            }
            this.isLoading = true;
            this.$http({
                url: 'password_resets?uid=' + this.$route.query.uid + '&reset_token=' + this.$route.query.reset_token,
                method: 'PUT',
                data: { password: this.password }
            }).then((response) => {
                this.isLoading = false;
                switch (response.data['status']) {
                    case 'password_reset':
                        this.state = 5;
                        break;
                }
            }).catch((err) => {
                if (err.response.status == 404) {
                    this.state = 1;
                    this.isLoading = false;
                    this.$q.notify({
                        message: 'There has been a mistake. Please try to send a request again.',
                        color: 'warning'
                    });
                }
            })
        }
    },
}
